/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*----------------------------------------------------------------------*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import {ProductBox} from '../../components/boxen';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, "\n", React.createElement(Card, {
    sx: {
      paddingLeft: '1em',
      paddingBottom: '1em'
    }
  }, React.createElement(_components.h1, null, "WD Blog Contents"), React.createElement(Box, {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  }, React.createElement(ProductBox, {
    maintitle: "Blog Entries"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/blog/amplify"
  }, "Gatsby Amplified"), " -\nmodern web apps with all the trimmings on the cheap with AWS"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/blog/git"
  }, "git guides"), " - source control bang for the buck"), "\n")), React.createElement(ProductBox, {
    maintitle: "Notes"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/blog/upgrades"
  }, "major updates")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/blog/gatsby"
  }, "gatsby")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/blog/markdown"
  }, "markdown")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/blog/testing"
  }, "testing")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/blog/pwa"
  }, "pwa")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/blog/spapi"
  }, "spapi")), "\n")), React.createElement(ProductBox, {
    maintitle: "Dev Links"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pagespeed.web.dev/"
  }, "lighthouse")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://css-tricks.com/snippets/css/a-guide-to-flexbox/"
  }, "flexbox")), "\n", React.createElement(_components.li, null, "mui\n", React.createElement(_components.a, {
    href: "https://mui.com/material-ui/getting-started/supported-components/"
  }, "components"), ",\n", React.createElement(_components.a, {
    href: "https://mui.com/material-ui/customization/default-theme/"
  }, "defaults"), ",\n", React.createElement(_components.a, {
    href: "https://mui.com/system/basics/"
  }, "sys")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tc39.es/ecma262/multipage/"
  }, "ecma spec")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cheatsheetseries.owasp.org/index.html"
  }, "owasp appsec")), "\n")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
